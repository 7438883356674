// @flow
import { isEmpty } from '@/_common/utils/array-utils';
import _, { capitalize, isArray, isNil, trim } from 'lodash';
import randomString from './random';
import store from 'store';
import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import useSWR from 'swr';
import orderPaymentApi from '@/apis/gpuResources/order-payment-api';
import TenantApi from '@/apis/tenant/tenant-api';
import UserAPI from '@/apis/userCenter/user-api';
import settings from '@/_common/utils/settings';
import logoImg from '@/app/_common/assets/logo.svg';
import datastoneLogo from '@/app/_common/assets/datastone-logo.svg';
const CURRENT_SAVED_WORKSPACE = 'currentSavedWorkspace';
const CURRENT_SAVED_SPECDATA = 'currentSavedSpecData';
const CURRENT_SAVED_ENV = 'currentSavedEnv';
const AUTH_INFO = 'authInfo';

export function isRootUser() {
  const authInfo = getAuthInfo();
  return authInfo?.root;
}

export function getAuthInfo() {
  return store.get(AUTH_INFO);
}
export function getTenantId() {
  return getAuthInfo()?.tenant_id;
}
export function getTenantName() {
  return getAuthInfo()?.tenant_name;
}
export function getUserId() {
  return getAuthInfo()?.user_id;
}
export function getUserName() {
  return getAuthInfo()?.username;
}

export function useTenantEmail() {
  const { data } = useSWR(['getUserInfo'], async (key) => {
    const res = await UserAPI.getUserInfo();
    return res?.data?.email ?? '';
  });

  return data;
}

export function getCurrentSavedWorkspace() {
  return store.get(CURRENT_SAVED_WORKSPACE);
}

export function setCurrentSavedWorkspace(data) {
  setCurrentSavedSpecData(null);
  store.set(CURRENT_SAVED_WORKSPACE, data);
}

export function getCurrentSavedEnv() {
  return store.get(CURRENT_SAVED_ENV);
}

export function setCurrentSavedEnv(data) {
  store.set(CURRENT_SAVED_ENV, data);
}

export function getCurrentSavedSpecData() {
  return store.get(CURRENT_SAVED_SPECDATA);
}

export function setCurrentSavedSpecData(data) {
  store.set(CURRENT_SAVED_SPECDATA, data);
}

export function isMobxArray(data: any[]): boolean {
  if (!(typeof data === 'object')) {
    return false;
  }
  if (!data.$mobx) {
    return false;
  }

  return true;
}

export function vh(v) {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (v * h) / 100;
}

export function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

export function delayPromise(msec) {
  return new Promise((reslove, reject) => {
    setTimeout(() => {
      reslove();
    }, msec);
  });
}

export function tryFormatJson(json) {
  try {
    return JSON.stringify(JSON.parse(json), null, '\t');
  } catch (err) {}
  return json;
}

function customCamelCase(key) {
  const tmp = trim(key);
  if (!tmp) return tmp;
  return tmp
    .split('_')
    .map((e, idx) => {
      if (idx === 0) return e;
      return capitalize(e);
    })
    .join('');
}

export function camelizeKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [customCamelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export function submitForm(form) {
  //get the form element's document to create the input control with
  //(this way will work across windows in IE8)
  var button = form.ownerDocument.createElement('input');
  //make sure it can't be seen/disrupts layout (even momentarily)
  button.style.display = 'none';
  //make it such that it will invoke submit if clicked
  button.type = 'submit';
  //append it and click it
  form.appendChild(button).click();
  //if it was prevented, make sure we don't get a build up of buttons
  form.removeChild(button);
}

export function itemAddKey(param) {
  if (isArray(param)) {
    return param.map((e) => ({ ...e, key: randomString(10) }));
  } else {
    return { ...param, key: randomString(10) };
  }
}

export function itemRemoveKey(arr) {
  return arr.map((e) => {
    const tmp = { ...e };
    delete tmp.key;
    return tmp;
  });
}

export function markedPromise(count, callback) {
  const [promise, resolve, reject] = splitPromise();
  let marked = {};
  let resolved = false;
  const marker = (key, value) => {
    if (resolved) return;
    marked[`${key}`] = isNil(value) ? true : value;
    if (Object.keys(marked).length >= count) {
      resolved = true;
      resolve(marked);
    }
  };
  if (callback) {
    promise.then(callback);
  }
  return { marker, promise };
}

export function splitPromise() {
  const obj = {};
  const promise = new Promise((resolve, reject) => {
    obj.resolve = resolve;
    obj.reject = reject;
  });
  return [promise, obj.resolve, obj.reject];
}

export function hasText(text) {
  return trim(text).length > 0;
}

export function isNilOrEmpty(v) {
  return !notNilOrEmpty(v);
}

export function notNilOrEmpty(v) {
  if (isNil(v)) {
    return false;
  }
  if (isEmpty(v)) return false;
  return true;
}

export function firstHaveText(...vars) {
  for (let i = 0; i < vars.length; i++) {
    if (hasText(vars[i])) {
      return vars[i];
    }
  }
  return null;
}

export function trimValues(obj) {
  const keys = Object.keys(obj);
  return keys.reduce((acc, cur) => {
    acc[cur] = trim(obj[cur]);
    return acc;
  }, {});
}

export function parseResponseError(err) {
  // console.log(err, 'err');
  let message = err?.response?.data?.message;
  if (message) {
    return message;
  }
  message = err?.message;
  if (message) {
    return message;
  }
  return err;
}
export const parseResponseErrorWithCode = (err) => {
  const code = err?.response?.data?.code;
  if (code) {
    return code;
  }
  return null;
};
export const parseResponseErrorWithDetail = (err) => {
  const detail = err?.response?.data?.detail;
  if (detail) {
    return detail;
  }
  return null;
};
export function getValueFromPropsOrURL(props, name, name2) {
  return props[name] ?? props.match?.params?.[name2 ?? name];
}

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function tryParseJSON(json) {
  if (isEmpty(json)) {
    return null;
  }
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log('try parse json failure', json, e);
  }
  return null;
}

export function downloadFile({ blob, filename }) {
  var a = document.createElement('a');
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function readFileContentAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onabort = () => reject('file reading was aborted');
    reader.onerror = () => reject('file reading has failed');
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsText(file);
  });
}
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export function determineSelectedItem(list = [], old, filterFn) {
  if (list.length === 0) {
    return null;
  }
  if (isNil(old)) {
    return list[0];
  }
  if (isNil(filterFn)) {
    filterFn = (a, b) => `${a.id}` === `${b.id}`;
  }
  const tmp = list.filter((e) => filterFn(e, old))?.[0];
  if (isNil(tmp)) {
    return list[0];
  }
  return tmp;
}

export function setAllSaved(specData, setSpecData) {
  let tempSpecData = _.cloneDeep(specData);
  if (
    tempSpecData &&
    tempSpecData.test_cases &&
    tempSpecData.test_cases.length > 0
  ) {
    tempSpecData.test_cases.forEach((collection) => {
      if (collection && collection.values && collection.values.length > 0) {
        collection.values.forEach((test_case) => {
          test_case.saved = true;
        });
      }
    });
  }
  if (
    tempSpecData &&
    tempSpecData.test_workflows &&
    tempSpecData.test_workflows.length > 0
  ) {
    tempSpecData.test_workflows.forEach((test_workflow) => {
      test_workflow.saved = true;
    });
  }
  if (
    tempSpecData &&
    tempSpecData.traffic_models &&
    tempSpecData.traffic_models.length > 0
  ) {
    tempSpecData.traffic_models.forEach((traffic_model) => {
      traffic_model.saved = true;
    });
  }
  setSpecData(tempSpecData);
  return tempSpecData;
}

export const FN_EMPTY = () => {};
export const OBJ_EMPTY = {};
export const ARR_EMPTY = [];

export const isDiskMetrics = (metric) => {
  if (typeof metric === 'string') {
    return metric.includes('serverinfo-disk-total-metric');
  }
};

export const getDiskList = (chartData, metrics) => {
  if (!isDiskMetrics(metrics)) return [];
  let diskList = [];
  if (chartData && chartData.length > 0) {
    chartData.forEach((data) => {
      const keys = data.keys;
      //keys Is a list, i need to get the disk name from the list
      //Merge arrays and remove duplicates
      diskList = [...new Set([...diskList, ...keys])];
    });
  }
  return diskList;
};

export const getNewChartData = (chartData, metrics, currentDisk) => {
  if (!isDiskMetrics(metrics)) return chartData;
  if (chartData && chartData.length > 0) {
    return chartData.filter((data) => {
      const keys = data.keys;
      if (keys.includes(currentDisk)) {
        return true;
      }
      return false;
    });
  }
  return chartData;
};

export const localLastZoneDomainServiceKey = 'LAST_ZONE_DOMAIN_SERVICE';

export function getLastZoneDomainService(props) {
  let tempZone = '';
  let tempDomain = '';
  let tempService = '';

  function handleLocalLastZoneDomainService() {
    let localLastZoneDomainService = Cookies.get(localLastZoneDomainServiceKey);
    if (localLastZoneDomainService) {
      let array = localLastZoneDomainService.split('/');
      if (array && array.length > 0) {
        if (array.length >= 1) {
          tempZone = array[0];
        }
        if (array.length >= 2) {
          tempDomain = array[1];
        }
        if (array.length >= 3) {
          tempService = array[2];
        }
      }
    }
  }

  if (props && props.location && props.location.search) {
    const search = queryString.parse(props.location.search);
    console.log('search', search);

    let queryZone = '';
    let queryDomain = '';
    let queryService = '';

    if (search && Object.keys(search).length > 0) {
      for (let key in search) {
        if (key === 'zone' && search['zone']) {
          queryZone = search['zone'];
        }
        if (key === 'domain' && search['domain']) {
          queryDomain = search['domain'];
        }
        if (key === 'service' && search['service']) {
          queryService = search['service'];
        }
      }
    }

    if (queryZone || queryDomain || queryService) {
      tempZone = queryZone;
      tempDomain = queryDomain;
      tempService = queryService;
    } else {
      handleLocalLastZoneDomainService();
    }
  } else {
    handleLocalLastZoneDomainService();
  }

  let lastZoneDomainService = {
    zone: tempZone,
    domain: tempDomain,
    service: tempService,
  };
  // console.log('lastZoneDomainService', lastZoneDomainService)

  return lastZoneDomainService;
}

export function useLastZoneDomainService() {
  const query = useQuery();

  let tempZone = '';
  let tempDomain = '';
  let tempService = '';

  function handleLocalLastZoneDomainService() {
    let localLastZoneDomainService = Cookies.get(localLastZoneDomainServiceKey);
    if (localLastZoneDomainService) {
      let array = localLastZoneDomainService.split('/');
      if (array && array.length > 0) {
        if (array.length >= 1) {
          tempZone = array[0];
        }
        if (array.length >= 2) {
          tempDomain = array[1];
        }
        if (array.length >= 3) {
          tempService = array[2];
        }
      }
    }
  }

  const lastZoneDomainService = useMemo(() => {
    let queryZone = query.get('zone');
    let queryDomain = query.get('domain');
    let queryService = query.get('service');
    console.log('queryZone', queryZone);
    console.log('queryDomain', queryDomain);
    console.log('queryService', queryService);

    if (queryZone || queryDomain || queryService) {
      tempZone = queryZone || '';
      tempDomain = queryDomain || '';
      tempService = queryService || '';
    } else {
      handleLocalLastZoneDomainService();
    }
    let tempLastZoneDomainService = {
      zone: tempZone,
      domain: tempDomain,
      service: tempService,
    };
    // console.log('lastZoneDomainService', tempLastZoneDomainService)

    return tempLastZoneDomainService;
  }, [query]);

  return lastZoneDomainService;
}

export const hasRole = (allowedRoles) => {
  return allowedRoles.some((role) => getAuthInfo()?.role_set.includes(role));
};

export const getLogo = () => {
  const provider = settings.PROVIDER;
  if (provider && provider === 'datastone') {
    return datastoneLogo;
  }
  return logoImg;
};

export const getProviderTitle = () => {
  const provider = settings.PROVIDER;
  if (provider && provider === 'datastone') {
    return 'DATA STONE';
  }
  return 'MegaEase Cloud';
};

export const isMaintenance = () => {
  if (settings.MAINTENANCE_STATUS === 'maintenance') {
    if (isRootUser()) {
      return false;
    }
    return true;
  }
  return false;
};
